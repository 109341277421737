<template>
    <BaseTrigger type="video" :label="label" @click="OnTriggerClicked" :sound="sound">
        <slot></slot>
    </BaseTrigger>
</template>

<script>
import BaseTrigger from '../BaseTrigger.vue';
import EventBus from '@/classes/event-bus';

export default {
    props: [
        'url', 
        'title',
        'label', 
        'sound', 
        'mode', 
        'onEnd', 
        'onPlay', 
        'onPause',
        'onClose'
    ],
    components: {
        BaseTrigger
    },
    mounted() {
        if (this.url == undefined) {
            console.error("URL trigger is missing a 'url' property");
        }
    },
    methods: {
        OnTriggerClicked() {
            EventBus.Broadcast(EventBus.CHANNELS.Global, "video-trigger", {
                url: this.url,
                title: this.title ?? null,
                mode: this.mode ?? 'youtube',
                onEnd: this.onEnd ?? (() => {}),
                onPlay: this.onPlay ?? (() => {}),
                onPause: this.onPause ?? (() => {}),
                onClose: this.onClose ?? (() => {})
            });
        }
    }
}
</script>

<style lang="scss">
.trigger-video {
    // background-image: url('../../assets/images/trigger-green.svg') !important;
}
</style>