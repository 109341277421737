<template>
    <BaseScene 
        name="rehearsal-stage" 
        :background="ResourceManager.Get('scenes.rehearsalstage')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"           
    >
        <SceneTrigger x="-21.2" y="3" scene="tongue-twister"/>
        <SceneTrigger x="25.5" y="3" scene="fool-fight"/>
        <!-- <SceneTrigger x="-1.5" y="0.5"/> -->

        <VideoTrigger 
            x="-1.5" 
            y="0.5"
            :url="cms.get('rehearsalstage', 'de').get('outtakes', 'de')"
            mode="array"
            :sound="ResourceManager.Get('laughs.arek.4')"
        />          
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import ResourceManager from "@/classes/resource-manager";
import { Howl } from 'howler';
import VideoTrigger from '../triggers/VideoTrigger.vue';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        SceneTrigger,
        VideoTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
        };
    },
    methods: {
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.rehearsalstage')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.stop();
        }
    }     
}
</script>